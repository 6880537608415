import gql from 'graphql-tag'

import { initializeApollo } from '../../lib/apolloClient'
import { CHECKOUT_FRAGMENT } from '../../lib/shopifyFragments'
import {
  ShopifyCheckoutLineItemsReplaceMutation,
  ShopifyCheckoutLineItemsReplaceMutationVariables,
} from '../../types/codegen/shopify'

const replaceLineItems = async (
  variables: ShopifyCheckoutLineItemsReplaceMutationVariables
) => {
  const apolloClient = initializeApollo()

  const result = await apolloClient.mutate<
    ShopifyCheckoutLineItemsReplaceMutation
  >({
    mutation: gql`
      ${CHECKOUT_FRAGMENT}
      mutation ShopifyCheckoutLineItemsReplace(
        $checkoutId: ID!
        $lineItems: [CheckoutLineItemInput!]!
      ) {
        checkoutLineItemsReplace(
          checkoutId: $checkoutId
          lineItems: $lineItems
        ) {
          checkout {
            ...checkout
          }
        }
      }
    `,
    variables,
  })

  return result?.data?.checkoutLineItemsReplace?.checkout
}

export default replaceLineItems

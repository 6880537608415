import gql from 'graphql-tag'

import { initializeApollo } from '../../lib/apolloClient'
import { CHECKOUT_FRAGMENT } from '../../lib/shopifyFragments'
import {
  CheckoutLineItemInput,
  CurrencyCode,
  ShopifyCheckoutCreateMutation,
} from '../../types/codegen/shopify'

const createCheckout = async (
  currencyCode: CurrencyCode,
  lineItems?: CheckoutLineItemInput[]
) => {
  const apolloClient = initializeApollo()

  const result = await apolloClient.mutate<ShopifyCheckoutCreateMutation>({
    mutation: gql`
      ${CHECKOUT_FRAGMENT}
      mutation ShopifyCheckoutCreate(
        $currencyCode: CurrencyCode
        $lineItems: [CheckoutLineItemInput!]
      ) {
        checkoutCreate(
          input: {
            lineItems: $lineItems
            presentmentCurrencyCode: $currencyCode
          }
        ) {
          checkout {
            ...checkout
          }
        }
      }
    `,
    variables: {
      currencyCode,
      lineItems: lineItems || [],
    },
  })

  return result
}

export default createCheckout

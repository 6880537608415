import produce from 'immer'
import cookies from 'js-cookie'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import createCheckout from '../../../services/shopify/createCheckout'
import getCheckout from '../../../services/shopify/getCheckout'
import replaceLineItems from '../../../services/shopify/replaceLineItems'
import { RootReducerState } from '..'
import {
  CheckoutFragment,
  CheckoutLineItemFragment,
  CheckoutLineItemInput,
  CurrencyCode,
} from '../../../types/codegen/shopify'
import { StoreActionTypes, StoreActions, StoreState } from './types'

const CHECKOUT_COOKIE_ID = 'checkoutId'

const getLineItemInputs = (items: CheckoutLineItemFragment[] | undefined) => {
  // Get line items from current checkout
  // - reduce as CheckoutLineItemInputs
  const lineItemInputs: CheckoutLineItemInput[] | undefined = items?.reduce(
    (acc: CheckoutLineItemInput[], val) => {
      if (val.variant) {
        acc.push({
          quantity: val.quantity,
          variantId: val.variant.id,
        })
      }
      return acc
    },
    []
  )

  return lineItemInputs || []
}

// Action creators

export const storeCheckoutGet = (): ThunkAction<
  Promise<void>,
  {},
  {},
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    // TODO: double check usage of Partial
    getState: () => Partial<RootReducerState>
  ): Promise<void> => {
    dispatch({ type: StoreActionTypes.CHECKOUT_GET_START })

    // Get checkout ID cookie
    const existingCheckoutId = cookies.get(CHECKOUT_COOKIE_ID)

    // TODO: correctly type
    let existingCheckout
    if (existingCheckoutId) {
      const result = await getCheckout(existingCheckoutId)
      existingCheckout = result?.node
    }

    // @ts-ignore
    if (existingCheckout && !existingCheckout?.completedAt) {
      // Existing (non-completed) checkout found
      dispatch({
        payload: {
          checkout: existingCheckout,
        },
        type: StoreActionTypes.CHECKOUT_GET_COMPLETE,
      })
    } else {
      // No checkout found, create a new one
      dispatch({ type: StoreActionTypes.CHECKOUT_CREATE_START })

      // Get detected user currency from store, use default (store primary) currency if none found
      const detectedCurrency =
        getState()?.store?.detectedCurrency ||
        getState()?.store?.defaultCurrency

      // Create new checkout
      const { data } = await createCheckout(detectedCurrency as CurrencyCode)

      const newCheckout = data?.checkoutCreate?.checkout

      if (newCheckout) {
        // Store checkout id cookie
        cookies.set(CHECKOUT_COOKIE_ID, newCheckout.id, {
          expires: 1000 * 60 * 60 * 24 * 7, // 7 days
        })

        dispatch({
          payload: {
            checkout: newCheckout,
          },
          type: StoreActionTypes.CHECKOUT_CREATE_COMPLETE,
        })
      } else {
        // Dispatch error
        dispatch({ type: StoreActionTypes.CHECKOUT_CREATE_ERROR })
      }
    }
  }
}

export const storeLineItemAdd = (
  variantId: string,
  quantity: number
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    // TODO: double check usage of Partial
    getState: () => Partial<RootReducerState>
  ): Promise<void> => {
    dispatch({ type: StoreActionTypes.CHECKOUT_LINE_ITEM_ADD_START })

    // Get existing checkout from store
    const checkout = getState()?.store?.checkout
    const checkoutId = checkout?.id

    // Get line items (inputs)
    const lineItems = getLineItemInputs(
      checkout?.lineItems?.edges?.map(edge => edge.node)
    )

    // If we're unable to get a checkout ID, our checkout hasn't been created yet
    if (!checkoutId) {
      // TODO: dispatch sentry error?
      dispatch({ type: StoreActionTypes.CHECKOUT_LINE_ITEM_ADD_ERROR })
      return
    }

    // See if added variantID already exists in our checkout
    // If so, update quantities
    // If not, create a new checkout input item
    const lineItemIndex = lineItems?.findIndex(
      lineItem => lineItem?.variantId === variantId
    )

    if (lineItemIndex >= 0) {
      lineItems[lineItemIndex].quantity += quantity
    } else {
      lineItems.push({
        quantity,
        variantId,
      })
    }

    const updatedCheckout = await replaceLineItems({ checkoutId, lineItems })

    dispatch({
      payload: {
        checkout: updatedCheckout,
      },
      type: StoreActionTypes.CHECKOUT_LINE_ITEM_ADD_COMPLETE,
    })
  }
}

export const storeLineItemRemove = (variantId: string) => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    // TODO: double check usage of Partial
    getState: () => Partial<RootReducerState>
  ) => {
    dispatch({ type: StoreActionTypes.CHECKOUT_LINE_ITEM_REMOVE_START })

    // Get existing checkout from store
    const checkout = getState()?.store?.checkout
    const checkoutId = checkout?.id

    // Get line items (inputs)
    const lineItems = getLineItemInputs(
      checkout?.lineItems?.edges?.map(edge => edge.node)
    )

    // If we're unable to get a checkout ID, our checkout hasn't been created yet
    if (!checkoutId) {
      // TODO: dispatch sentry error?
      dispatch({ type: StoreActionTypes.CHECKOUT_LINE_ITEM_REMOVE_ERROR })
      return
    }

    // Remove line items with matching variant ID
    const filteredLineItems = lineItems.filter(
      lineItem => lineItem.variantId !== variantId
    )

    const updatedCheckout = await replaceLineItems({
      checkoutId,
      lineItems: filteredLineItems,
    })

    dispatch({
      payload: {
        checkout: updatedCheckout,
      },
      type: StoreActionTypes.CHECKOUT_LINE_ITEM_REMOVE_COMPLETE,
    })
  }
}

export const storeLineItemUpdate = (variantId: string, quantity: number) => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    // TODO: double check usage of Partial
    getState: () => Partial<RootReducerState>
  ) => {
    dispatch({ type: StoreActionTypes.CHECKOUT_LINE_ITEM_UPDATE_START })

    // Get existing checkout from store
    const checkout = getState()?.store?.checkout
    const checkoutId = checkout?.id

    // Get line items (inputs)
    const lineItems = getLineItemInputs(
      checkout?.lineItems?.edges?.map(edge => edge.node)
    )

    // If we're unable to get a checkout ID, our checkout hasn't been created yet
    if (!checkoutId || !lineItems) {
      // TODO: dispatch sentry error?
      dispatch({ type: StoreActionTypes.CHECKOUT_LINE_ITEM_UPDATE_ERROR })
      return
    }

    // See if added variantID already exists in our checkout
    // If so, update quantities
    const lineItemIndex = lineItems?.findIndex(
      lineItem => lineItem?.variantId === variantId
    )

    if (lineItemIndex >= 0) {
      lineItems[lineItemIndex].quantity = quantity
    }

    const updatedCheckout = await replaceLineItems({ checkoutId, lineItems })

    dispatch({
      payload: {
        checkout: updatedCheckout,
      },
      type: StoreActionTypes.CHECKOUT_LINE_ITEM_UPDATE_COMPLETE,
    })
  }
}

export const storePresentmentCurrencySet = (
  presentmentCurrency: CurrencyCode
) => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    // TODO: double check usage of Partial
    getState: () => Partial<RootReducerState>
  ) => {
    dispatch({
      payload: { presentmentCurrency },
      type: StoreActionTypes.PRESENTMENT_CURRENCY_SET_START,
    })

    // Get existing checkout from store
    const checkout = getState()?.store?.checkout

    // Get line items (inputs)
    const lineItems = getLineItemInputs(
      checkout?.lineItems?.edges?.map(edge => edge.node)
    )

    // Create new checkout with new currency code
    const { data } = await createCheckout(
      presentmentCurrency as CurrencyCode,
      lineItems
    )
    const updatedCheckout = data?.checkoutCreate?.checkout

    // TODO: DRY cookie setting?
    if (updatedCheckout) {
      // Store checkout id cookie
      cookies.set(CHECKOUT_COOKIE_ID, updatedCheckout.id, {
        expires: 1000 * 60 * 60 * 24 * 7, // 7 days
      })

      dispatch({
        payload: {
          checkout: updatedCheckout,
        },
        type: StoreActionTypes.PRESENTMENT_CURRENCY_SET_COMPLETE,
      })
    } else {
      // TODO: dispatch error? or should that be wrapped in a try ... catch?
    }
  }
}

// Reducer

export const initialState = {
  checkout: {},
  checkoutUpdating: false,
  defaultCurrency: undefined,
  // TODO: geolocate and set on mount
  detectedCurrency: 'GBP' as CurrencyCode,
  lineItemAdding: false,
  lineItemRemoving: false,
  lineItemUpdating: false,
  presentmentCurrencies: undefined,
}

export default function cartReducer(
  state: StoreState = initialState,
  action: StoreActions
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      // Checkout created
      // TODO: do we need to blow away existing checkout?
      case StoreActionTypes.CHECKOUT_CREATE_COMPLETE:
        draft.checkout = action.payload.checkout
        draft.checkoutUpdating = false
        break
      case StoreActionTypes.CHECKOUT_CREATE_START:
        draft.checkoutUpdating = true
        break
      // Checkout: retrieval
      case StoreActionTypes.CHECKOUT_GET_COMPLETE:
        draft.checkout = action.payload.checkout
        draft.checkoutUpdating = false
        break
      case StoreActionTypes.CHECKOUT_GET_START:
        draft.checkoutUpdating = true
        break
      // Line items: add
      case StoreActionTypes.CHECKOUT_LINE_ITEM_ADD_COMPLETE:
        draft.checkout = action.payload.checkout
        draft.checkoutUpdating = false
        draft.lineItemAdding = false
        break
      case StoreActionTypes.CHECKOUT_LINE_ITEM_ADD_ERROR:
        draft.checkoutUpdating = false
        draft.lineItemAdding = false
        break
      case StoreActionTypes.CHECKOUT_LINE_ITEM_ADD_START:
        draft.checkoutUpdating = true
        draft.lineItemAdding = true
        break
      // Line items: remove
      case StoreActionTypes.CHECKOUT_LINE_ITEM_REMOVE_COMPLETE:
        draft.checkout = action.payload.checkout
        draft.checkoutUpdating = false
        draft.lineItemRemoving = false
        break
      case StoreActionTypes.CHECKOUT_LINE_ITEM_REMOVE_ERROR:
        draft.checkoutUpdating = false
        draft.lineItemRemoving = false
        break
      case StoreActionTypes.CHECKOUT_LINE_ITEM_REMOVE_START:
        draft.checkoutUpdating = true
        draft.lineItemRemoving = true
        break
      // Line items: update
      case StoreActionTypes.CHECKOUT_LINE_ITEM_UPDATE_COMPLETE:
        draft.checkout = action.payload.checkout
        draft.checkoutUpdating = false
        draft.lineItemUpdating = false
        break
      case StoreActionTypes.CHECKOUT_LINE_ITEM_UPDATE_ERROR:
        draft.checkoutUpdating = false
        draft.lineItemUpdating = false
        break
      case StoreActionTypes.CHECKOUT_LINE_ITEM_UPDATE_START:
        draft.checkoutUpdating = true
        draft.lineItemUpdating = true
        break
      // Presentment currency
      case StoreActionTypes.PRESENTMENT_CURRENCY_SET_COMPLETE:
        draft.checkout = action.payload.checkout
        draft.checkoutUpdating = false
        break
      case StoreActionTypes.PRESENTMENT_CURRENCY_SET_ERROR:
        draft.checkoutUpdating = false
        break
      case StoreActionTypes.PRESENTMENT_CURRENCY_SET_START:
        draft.checkoutUpdating = true
        break
    }
  })
}

// Action types

import { CheckoutFragment, CurrencyCode } from '../../../types/codegen/shopify'

export enum StoreActionTypes {
  CHECKOUT_CREATE_COMPLETE = 'STORE_CHECKOUT_CREATE_COMPLETE',
  CHECKOUT_CREATE_ERROR = 'STORE_CHECKOUT_CREATE_ERROR',
  CHECKOUT_CREATE_START = 'STORE_CHECKOUT_CREATE_START',
  CHECKOUT_GET_START = 'STORE_CHECKOUT_GET_START',
  CHECKOUT_GET_COMPLETE = 'STORE_CHECKOUT_GET_COMPLETE',
  CHECKOUT_LINE_ITEM_ADD_COMPLETE = 'STORE_CHECKOUT_LINE_ITEM_ADD_COMPLETE',
  CHECKOUT_LINE_ITEM_ADD_START = 'STORE_CHECKOUT_LINE_ITEM_ADD_START',
  CHECKOUT_LINE_ITEM_ADD_ERROR = 'STORE_CHECKOUT_LINE_ITEM_ADD_ERROR',
  CHECKOUT_LINE_ITEM_REMOVE_COMPLETE = 'STORE_CHECKOUT_LINE_ITEM_REMOVE_COMPLETE',
  CHECKOUT_LINE_ITEM_REMOVE_ERROR = 'STORE_CHECKOUT_LINE_ITEM_REMOVE_ERROR',
  CHECKOUT_LINE_ITEM_REMOVE_START = 'STORE_CHECKOUT_LINE_ITEM_REMOVE_START',
  CHECKOUT_LINE_ITEM_UPDATE_COMPLETE = 'STORE_CHECKOUT_LINE_ITEM_UPDATE_COMPLETE',
  CHECKOUT_LINE_ITEM_UPDATE_ERROR = 'STORE_CHECKOUT_LINE_ITEM_UPDATE_ERROR',
  CHECKOUT_LINE_ITEM_UPDATE_START = 'STORE_CHECKOUT_LINE_ITEM_UPDATE_START',
  PRESENTMENT_CURRENCY_SET_COMPLETE = 'STORE_PRESENTMENT_CURRENCY_SET_COMPLETE',
  PRESENTMENT_CURRENCY_SET_ERROR = 'STORE_PRESENTMENT_CURRENCY_SET_ERROR',
  PRESENTMENT_CURRENCY_SET_START = 'STORE_PRESENTMENT_CURRENCY_SET_START',
}

// Action definitions

type CheckoutCreateComplete = {
  payload: {
    checkout: CheckoutFragment
  }
  type: typeof StoreActionTypes.CHECKOUT_CREATE_COMPLETE
}

type CheckoutCreateError = {
  type: typeof StoreActionTypes.CHECKOUT_CREATE_ERROR
}

type CheckoutCreateStart = {
  type: typeof StoreActionTypes.CHECKOUT_CREATE_START
}

type CheckoutGetComplete = {
  payload: {
    checkout: CheckoutFragment
  }
  type: typeof StoreActionTypes.CHECKOUT_GET_COMPLETE
}

type CheckoutGetStart = {
  type: typeof StoreActionTypes.CHECKOUT_GET_START
}

type CheckoutLineItemAddComplete = {
  payload: {
    checkout: CheckoutFragment
  }
  type: typeof StoreActionTypes.CHECKOUT_LINE_ITEM_ADD_COMPLETE
}

type CheckoutLineItemAddError = {
  type: typeof StoreActionTypes.CHECKOUT_LINE_ITEM_ADD_ERROR
}

type CheckoutLineItemAddStart = {
  type: typeof StoreActionTypes.CHECKOUT_LINE_ITEM_ADD_START
}

type CheckoutLineItemRemoveComplete = {
  payload: {
    checkout: CheckoutFragment
  }
  type: typeof StoreActionTypes.CHECKOUT_LINE_ITEM_REMOVE_COMPLETE
}

type CheckoutLineItemRemoveError = {
  type: typeof StoreActionTypes.CHECKOUT_LINE_ITEM_REMOVE_ERROR
}

type CheckoutLineItemRemoveStart = {
  type: typeof StoreActionTypes.CHECKOUT_LINE_ITEM_REMOVE_START
}

type CheckoutLineItemsUpdateComplete = {
  payload: {
    checkout: CheckoutFragment
  }
  type: typeof StoreActionTypes.CHECKOUT_LINE_ITEM_UPDATE_COMPLETE
}

type CheckoutLineItemsUpdateError = {
  type: typeof StoreActionTypes.CHECKOUT_LINE_ITEM_UPDATE_ERROR
}

type CheckoutLineItemsUpdateStart = {
  type: typeof StoreActionTypes.CHECKOUT_LINE_ITEM_UPDATE_START
}

type PresentmentCurrencySetComplete = {
  payload: {
    checkout: CheckoutFragment
  }
  type: typeof StoreActionTypes.PRESENTMENT_CURRENCY_SET_COMPLETE
}

type PresentmentCurrencySetError = {
  type: typeof StoreActionTypes.PRESENTMENT_CURRENCY_SET_ERROR
}

type PresentmentCurrencySetStart = {
  payload: {
    presentmentCurrency: CurrencyCode
  }
  type: typeof StoreActionTypes.PRESENTMENT_CURRENCY_SET_START
}

// Union Action types

export type StoreActions =
  | CheckoutCreateStart
  | CheckoutCreateComplete
  | CheckoutCreateError
  | CheckoutGetStart
  | CheckoutGetComplete
  | CheckoutLineItemAddStart
  | CheckoutLineItemAddComplete
  | CheckoutLineItemAddError
  | CheckoutLineItemRemoveComplete
  | CheckoutLineItemRemoveError
  | CheckoutLineItemRemoveStart
  | CheckoutLineItemsUpdateComplete
  | CheckoutLineItemsUpdateError
  | CheckoutLineItemsUpdateStart
  | PresentmentCurrencySetComplete
  | PresentmentCurrencySetError
  | PresentmentCurrencySetStart

// Reducer

export type StoreState = {
  checkout: Partial<CheckoutFragment>
  checkoutUpdating: boolean
  defaultCurrency?: string
  detectedCurrency?: CurrencyCode
  lineItemAdding: boolean
  lineItemRemoving: boolean
  lineItemUpdating: boolean
  presentmentCurrencies?: CurrencyCode[]
}

import gql from 'graphql-tag'

import { initializeApollo } from '../../lib/apolloClient'
import { CHECKOUT_FRAGMENT } from '../../lib/shopifyFragments'
import {
  CheckoutFragment,
  ShopifyCheckoutQuery,
} from '../../types/codegen/shopify'

const getCheckout = async (checkoutId: string) => {
  const apolloClient = initializeApollo()

  const result = await apolloClient.query<ShopifyCheckoutQuery>({
    query: gql`
      ${CHECKOUT_FRAGMENT}
      query ShopifyCheckout($checkoutId: ID!) {
        node(id: $checkoutId) {
          ... on Checkout {
            ...checkout
          }
        }
      }
    `,
    variables: {
      checkoutId,
    },
  })

  return result?.data
}

export default getCheckout

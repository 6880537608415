import gql from 'graphql-tag'

export const CHECKOUT_LINE_ITEM_FRAGMENT = gql`
  fragment checkoutLineItem on CheckoutLineItem {
    id
    title
    variant {
      id
      title
      image {
        transformedSrc(maxWidth: 400)
      }
      presentmentPrices(first: 10) {
        edges {
          node {
            compareAtPrice {
              amount
              currencyCode
            }
            price {
              amount
              currencyCode
            }
          }
        }
      }
    }
    quantity
  }
`

export const CHECKOUT_FRAGMENT = gql`
  ${CHECKOUT_LINE_ITEM_FRAGMENT}
  fragment checkout on Checkout {
    completedAt
    currencyCode
    id
    lineItems(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...checkoutLineItem
        }
      }
    }
    subtotalPriceV2 {
      amount
      currencyCode
    }
    totalTaxV2 {
      amount
      currencyCode
    }
    totalPriceV2 {
      amount
      currencyCode
    }
    webUrl
  }
`

const PRODUCT_VARIANT_FRAGMENT = gql`
  fragment productVariant on ProductVariant {
    availableForSale
    id
    presentmentPrices(first: 10) {
      edges {
        node {
          compareAtPrice {
            amount
            currencyCode
          }
          price {
            amount
            currencyCode
          }
        }
      }
    }
    selectedOptions {
      name
      value
    }
    title
  }
`

export const PRODUCT_FRAGMENT = gql`
  ${PRODUCT_VARIANT_FRAGMENT}
  fragment product on Product {
    availableForSale
    id
    options {
      id
      name
      values
    }
    presentmentPriceRanges(first: 10) {
      edges {
        cursor
        node {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
      }
    }
    title
    variants(first: 50) {
      edges {
        node {
          ...productVariant
        }
      }
    }
  }
`
